.pacbio_app {
    width: 100vw;
    height: 100%;
    background-color: #fff;
}

.title_div {
    width: 100%;
    height: 10vh;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.title_div>h1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
}

.pac_div {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.625rem 0;
}

.pac_child {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.pac_title {
    width: 100%;
    height: 15%;
    /* background-color: pink; */
    line-height: 1.875rem;
    text-indent: 2rem;
    /* background-color: red; */
}

.pacbio_revio {
    width: 100%;
    /* height: 80vh; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.pacbio_child {
    width: 80%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.pacbio_title {
    width: 100%;
    height: 10%;

    text-indent: 2rem;
    display: flex;
    align-items: center;
}

.SMRT_principle {
    width: 100%;
    height: 15%;
    /* background-color: pink; */
    display: flex;
    flex-direction: column;
    text-indent: 3rem;
    margin: 0.625rem 0;
}

.SMRT_principle>p {
    margin: 0.625rem 0;
}

.Service_process {
    width: 100%;
    height: 70%;

}

.Service_process>p {
    width: 100%;
    height: 12%;
    /* background-color: pink; */
    text-indent: 3rem;
    display: flex;
    align-items: center;
}

.process_img {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.process_img>dl {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* .process_img>dl img{
    width: 80%;
    height: 80%;
} */
.process_img>dl dd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.service_context {
    width: 100%;
    height: 35%;
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0.625rem;
}

.service_context>p {
    width: 100%;
    height: 15%;
    /* background-color: pink; */
    text-indent: 3rem;
    display: flex;
    align-items: center;
    /* background-color: red; */
    margin: 0.625rem 0;
}

.service_context>table {
    width: 80%;
    height: 50%;
    border-collapse: collapse;
    margin: 0;
    text-align: center;
}

.service_context>table>thead {
    height: 40%;
    background-color: #0070C0;
    color: white;
}

.service_context>table>tbody {
    height: 60%;
}

.service_context>table>tbody td {
    border: .0625rem solid #0070C0;
}

.Prome_div {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3.125rem;
}

.prome_child {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prome_title {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-indent: 2rem;
}

.ONT_principle {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    text-indent: 3rem;
}

.ONT_principle>p {
    margin: 0.625rem 0;
}

.ONT_advantage {
    width: 100%;
    height: 66%;
    text-indent: 3rem;
    margin-top: 1.875rem;
}

.ONT_advantage>p {
    width: 100%;
    height: 5%;
    margin: 0.625rem 0;
}

.ONT_img {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ONT_img_list {
    width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ONT_img_list>p {
    width: 100%;
    text-align: center;
}

.ONT_img_list img {
    width: 50%;
    height: 50%;
    margin: 0.625rem auto;
}

.ONT_img_list:first-child>p:nth-child(3) {
    margin: 1.25rem 0;
}

.pac_Service_process {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pac_service_process_child {
    width: 80%;
    height: 100%;
    background-color: #fff;
}

.pac_service_process_child>p {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    text-indent: 3rem;
}
.pac_server_img_list{
    width: 100%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.pac_server_img_list>div{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.pac_server_img_list>div img{
    width: 65%;
    /* height: 65%; */
}
.service_context_div{
    width: 100%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.service_context_child{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.service_context_child>p{
    width: 100%;
    height: 15%;
    /* background-color: pink; */
    text-indent: 3rem;
    display: flex;
    align-items: center;
    /* background-color: red; */
    margin: 0.625rem 0;
}
.service_context_child>table{
    width: 80%;
    height: 50%;
    border-collapse: collapse;
    margin: 0;
    text-align: center;

}
.service_context_child>table>thead {
    height: 40%;
    background-color: #0070C0;
    color: white;
}

.service_context_child>table>tbody {
    height: 60%;
}

.service_context_child>table>tbody td {
    border: .0625rem solid #0070C0;
}
.my_advantage{
    width: 100%;
    /* height: 30vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 1.25rem 0; */
    /* margin-bottom: 1.25rem; */
}
.my_advantage_child{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.my_advantage_child>p:first-child{
    width: 100%;
    height: 15%;

    display: flex;
    align-items: center;
    text-indent: 3rem;
}