.deveopt_app{
    width: 100vw;
    height: 100%;
    background-color: #fff;
}
.deveopt_title{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.deveopt_title>h1{
    width: 80%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

