.present_div {
  width: 100vw;
  height: 100%;
  margin-top: 6.25rem;
  background-color: #fff;
}

.present_text_div {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.25rem;
  margin-top: -1.25rem;
}

.present_text {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.present_text > h1 {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  margin: 1.25rem 0;
  color: rgb(60, 211, 60);
}

.present_text > div {
  width: 100%;
  height: 80%;
  line-height: 3.125rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.BigTtile > p {
  width: 50%;
  height: 100%;
  text-indent: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BigTtile > img {
  width: 40%;
  height: 70%;
}

.high_performance {
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.high_child {
  width: 80%;
  height: 100%;
}

.high_child > h1 {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}

.high_text {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
}

.text_div {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.text_div > h1 {
  width: 80%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.text_child {
  width: 80%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.literature_item {
  width: 24%;
  height: 32%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  background-color: #fff;
  font-size: .75rem;
  cursor: pointer;
}

.literature_item > img {
  width: 90%;
  height: 60%;
  border: 0.0625rem dashed #000;
}

.Comfortable_office {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.125rem 0;
}
.Comfortable_office_child {
  width: 80%;
  height: 100%;
}

.offic_title {
  width: 100%;
  height: 10%;
  margin: .625rem 0;
}

.offic_imgItem {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.img_top {
  width: 100%;
  /* height: 30%; */
  display: flex;
  justify-content: space-around;

  justify-content: center;
  align-items: center;
}
.img_center {
  width: 100%;
  /* height: 30%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_bottom {
  width: 100%;
  /* height: 30%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.offic_imgItem img {
  margin: .125rem .125rem;
}
.off_tema {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 2.5rem 0; */
}
.off_tema_child {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.off_tema_child > h1 {
  width: 100%;
  height: 10vh;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.boss_present{
    width: 100%;
    /* height: ; */
    line-height: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.875rem 0;
}
.left{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 3.125rem;
}
.right{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.boss_job_position{
    width: 100%;
    height: 20%;
    font-size: 1.875rem;
    text-indent: 2rem;
    color: #00439F;
    margin: 1.875rem 0;
}
.boss_name{
    width: 100%;
    height: 15%;
    text-indent: 2rem;
}
.boss_name>span:first-child{
    color: #00439F;
    font-size: 1.25rem;
}
.boss_name>span:nth-child(2){
    color: #00439F;
    padding: 0 .625rem;
}
.boss_content{
    width: 100%;
    text-indent: 2rem;
}

.employee_div{
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    position: relative;
    margin-top: -60px;
    margin-bottom: 3.125rem;
}
.employee_title{
    width: 100%;
    height: 20%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-indent: 2rem;
    font-size: 1.4375rem;
    color: #00439F;
}
.employee_list{
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.employee_item{
   width: 26%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color:rgba(38, 50, 220, 0.041);
    font-size: .9375rem;
    flex: none;
    margin: 0 1.875rem;
    border-radius: .625rem;
}

.employee_name{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
}
.employee_position{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.0625rem;
    margin-bottom: .625rem;
}
.employee_content{
    width: 100%;
    height: 60%;
    text-indent: 3rem;
    line-height: 1.5625rem;
    padding: 0 .625rem;
    /* padding: 0 10px; */
}

.employee_leftButton,.employee_rightButton{
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 65%;
  border: none;
  background: transparent;
  
}
.employee_leftButton{
  left: 0;
}
.employee_rightButton{
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.Pemployee_div{
  width: 100%;
  height: 280vh;
}
.Pemployee_title{
  width: 100%;
  height: 10%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  text-indent: 2rem;
  font-size: 1.4375rem;
  color: #00439F;
}
.Pemp_item{
  width: 100%;
  height: 87%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Pemp_list{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: .625rem .625rem;
  margin: .625rem .125rem;
  background-color: #F6F7FE;
  border-radius: 1.25rem;
}