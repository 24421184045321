* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-color: #f4f4f4;
  scroll-behavior: smooth;

}
/* #root::-webkit-scrollbar {
  display: none;
} */
@media screen and (max-width:450px) {
  body{
    font-size: .75rem;
  }
}