.news_text{
    width: 100%;
    height: 100%;
}
.news_text_title{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.news_text_title>h1{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.time_div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.time_div>.time_div_child{
    width: 80%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-indent: 2rem;
    color: #ccc;
}