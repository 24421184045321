.analysis_app {
    width: 100vw;
    height: 100%;
    background-color: #fff;
}

.analays_title {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.analays_title>h1 {
    width: 80%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.analay_DNA {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.analay_DNA_child {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.DNA_title {
    width: 100%;
    height: 10%;
    text-indent: 2rem;
    font-size: 1.25rem;
    font-weight:bold;
    margin: 1.25rem 0;
}

.DNA_context {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-indent: 2.7rem;
}

.demo_anala{
    width: 100%;
    display: flex;
    align-items: center;
}

.demo_title{
    width: 100%;
    height: 30%;
    margin: 1.25rem 0;
    line-height: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.demo_title>p:first-child{
    width: 100%;
    height: 10%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-size: 1.25rem;
    text-indent: 3rem;
}
.demo_title>p:nth-child(2){
    width: 100%;
    height: 10%;
    text-indent: 3rem;
}

.demo_title>p:nth-child(3){
    width: 100%;
    height: 20%;
    text-indent: 3rem;
}
.demo_title>p:nth-child(3)>span:first-child{
    padding-right: 1.875rem;
}
.demo_title>p:nth-child(4){
    width: 100%;
    height: 20%;
    text-indent: 3rem;
}
.demo_title>p:nth-child(5){
    width: 100%;
    text-indent: 3.5rem;
}
.demo_title>p:nth-child(6){
    width: 100%;
}
.gwas_twas{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.25rem 0;
}
.gt_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.gwas_twas_title{
    width: 100%;
    height: 10%;
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0.625rem 0;
    text-indent: 2rem;
}

.gt_context{
    width: 100%;
    /* height: 20%; */
    line-height: 1.875rem;
    text-indent: 2.7rem;
}   



.gtdemo_anala{
    width: 100%;
    display: flex;
    align-items: center;
}

.gtdemo_title{
    width: 100%;
    height: 30%;
    margin: 1.25rem 0;
    line-height: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.gtdemo_title>p:first-child{
    width: 100%;
    height: 10%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-size: 1.25rem;
    text-indent: 3rem;
}
.gtdemo_title>p:nth-child(2){
    width: 100%;
    height: 10%;
    text-indent: 3rem;
}
.gtdemo_title>p:nth-child(3){
    width: 100%;
    height: 20%;
    text-indent: 3rem;
}
.gtdemo_title>p:nth-child(3)>span:first-child{
    padding-right: 1.875rem;
}
.gtdemo_title>p:nth-child(4){
    width: 100%;
    height: 20%;
    text-indent: 3rem;
}
.gtdemo_title>p:nth-child(5){
    width: 100%;
    text-indent: 3.5rem;
}
.gtdemo_title>p:nth-child(7){
    width: 80%;
margin-bottom: 0.625rem;
}

.gtdemo_title>p:nth-child(8){
    width: 100%;
}

.Methylate_atac{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Meth_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.meth_title{
    width: 100%;
    height: 10%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-indent: 2rem;
    font-size: 1.25rem;
    font-weight: bold;
    margin: 1.25rem 0;
}
.meth_context{
    width: 100%;
    line-height: 1.875rem;
    margin: 0.625rem 0;
}
.demo_meth{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.outcome_div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0.625rem 0;
}