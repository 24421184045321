.IlluminaTwo_app {
    width: 100vw;
    height: 100%;
    background-color: #fff;
}

.title_div {
    width: 100%;
    height: 10vh;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;

}

.title_div>h1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
}

.Brief_div {
    width: 100%;
    height: 50vh;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.625rem 0;
}

.Brief_child {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.Brief_title {
    width: 100%;
    height: 30%;
    /* background-color: pink; */
    line-height: 1.875rem;
    text-indent: 2rem;
}

.Brief_img {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Brief_img>img {
    height: 80%;
    margin: 0 4.375rem;
}

.Service_Content {
    width: 100%;
    /* height: 80vh; */
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.Service_child {
    width: 80%;
    /* height: 100%; */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.Service_child>h3 {
    width: 100%;
    height: 10%;
    text-indent: 2rem;
    display: flex;
    align-items: center;
    margin: 1.25rem 0;
}

.service_table {
    width: 100%;
    height: 43%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0.625rem 0;

}

.service_table>p {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-indent: 2.5rem;
    font-weight: bold;
    margin: 1.25rem 0;
}

.table {
    width: 80%;
    height: 90%;
    text-align: center;

    /* border: 1px solid #0070C0; */
    border-collapse: collapse;
    margin: 0;
}

.table>thead {
    /* width: 100%; */
    height: 7vh;
    margin: 0;
    padding: 0;
    background-color: #0070C0;
    color: white;
    font-weight: normal;
}

.table tbody td {
border: 1px solid #0070C0;
}


.table_two {
    width: 80%;
    height: 90%;
    text-align: center;

    /* border: 1px solid #0070C0; */
    border-collapse: collapse;
    margin: 0;
}

.table_two>thead {
    /* width: 100%; */
    height: 7vh;

    background-color: #0070C0;
    color: white;
    font-weight: normal;
}

.table_two tbody td {
    border: 1px solid #0070C0;
}




.client_new {
    width: 100%;
    height: 43%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0.625rem 0;

}

.client_new>p:first-child {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-indent: 2.5rem;
    font-weight: bold;
    margin: 0.625rem 0;
}
.client_table{
    width: 80%;
    height: 40%;
    border-collapse: collapse;
    margin: 0;
    text-align: center;
}
.client_table thead{
    width: 100%;
    height: 30%;

    background-color: #0070C0;
    color: white;
    font-weight: normal;
}
.client_table tbody td{
    border: 1px solid #0070C0;
}
.client_list{
    width: 80%;
    height: 40%;
    list-style-type: decimal;
    list-style-position: inside;
    margin: 0.625rem 0;
}
.client_list>li{
    margin: 0.625rem 0;
}
.client_list>li:nth-child(3)>p{
    margin: 0.3125rem 0;
}

.new_products {
    width: 100%;
    height: 43%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0.625rem 0;

}

.new_products>p {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-indent: 2.5rem;
    font-weight: bold;
    margin: 1.25rem 0;
}


.products_table{
    width: 80%;
    height: 40%;
    border-collapse: collapse;
    margin: 0;
    text-align: center;
}
.products_table thead{
    width: 100%;
    height: 30%;

    background-color: #0070C0;
    color: white;
    font-weight: normal;
}
.products_table tbody td{
    border: 1px solid #0070C0;
}


.products_list{
    width: 80%;
    height: 40%;
    list-style-type: decimal;
    list-style-position: inside;
    margin: 0.625rem 0;
}
.products_list>li{
    margin: 0.625rem 0;
}
.products_list>li:nth-child(3)>p{
    margin: 0.3125rem 0;
}
.Sample_requirements {
    width: 100%;
    height: 43%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0.625rem 0;

}

.Sample_requirements>p {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-indent: 2.5rem;
    font-weight: bold;
    margin: 1.25rem 0;
}



.advantage_div {
    width: 100%;
    height: 53vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem ;
    
    
}

.advantage_child {
    width: 80%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.advantage_child>h3 {
    width: 100%;
    height: 10%;
    text-indent: 2rem;
    display: flex;
    align-items: center;
    margin: 1.25rem 0;
    
}



.advantage_child>ul {
    width: 85%;
    height: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    
}

.advantage_child>ul>li {
    width: 23%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  
}

.advantage_child>ul>li>p {
    margin: 0.3125rem 0;
    
}

.advantage_child>ul>li>p:nth-child(2) {
    text-indent: 2rem;
line-height: 1.875rem; 
font-size: .775rem;
}
table{
    font-size: .9375rem;
}