.TextTwo_app {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text_two_child {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1.25rem 0;
}