.head_div {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    border: 3px solid #ccc;
}

ul {
    list-style: none;
    margin-right: 1.25rem;
}

.head_left,
.head_list {
width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.head_left {
    width: 40%;
    padding-left: 6.25rem;
    cursor: pointer;
}

.head_list {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* font-size: .8625rem; */

}

.head_list>li {
    cursor: pointer;
    margin: 0 .625rem;
}
.Mobile_div{
    width: 60%;
    height: 80%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 1.25rem;
    position: relative;
}
.List_Item{
    width: 100%;
    position: absolute;
    top: 80%;
    right: 0;
    z-index: 9999;
    border: .0938rem solid #ccc;

}


@media screen and (max-width: 450px) {
    .head_left {
        width: 50%;
        padding-left: 20px;
    }

    .logo_img {
        width: 100%;
        height: 50px;
    }

    .head_list {
        width: 30%;
        font-size: 10px;
    }

}