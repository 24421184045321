.text_app {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text_app_child {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1.25rem 0;
}

.text_p {
    width: 100%;
    text-indent: 2rem;
    line-height: 2.5rem;
    margin: .625rem 0;
}

.text_img_p {
    width: 100%;
    text-align: center;
    margin-bottom: .625rem;
}