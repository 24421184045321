.variation_app{
    width: 100vw;
    height: 100%;
    background-color: #fff;
}
.variation_title{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.variation_title>h1{
    width: 80%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.varitation_content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.25rem 0;
}
.varitation_content_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.varitation_content_child>p{
    width: 100%;
    line-height: 1.875rem;
    text-indent: 2rem;
}

.img_div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img_div_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img_div_child>p{
    margin-bottom: 1.25rem;
}