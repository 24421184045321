.Animals_app {
  width: 100vw;
    background-color: #fff;
  height: 100%;
  background-color: #fff;
}

.animals_title {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animals_title > h1 {
  width: 80%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.Product_overview {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product_child {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product_child > p:first-child {
  width: 100%;
  height: 20%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 1.25rem;
  font-weight: bold;
  text-indent: 2rem;
}

.product_child > p:nth-child(2) {
  width: 100%;
  height: 80%;
  line-height: 1.875rem;
  text-indent: 3rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.product_good {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.good_child {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.good_child > p:first-child {
  width: 100%;
  height: 20%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 1.25rem;
  font-weight: bold;
  text-indent: 2rem;
}
.good_item {
  width: 100%;
  height: 40%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* background-color: green; */
  padding-left: 12.5rem;
}
.good_item > p {
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  text-indent: 1.7rem;
}
.product_app {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product_app_child {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product_app_child > p:first-child {
  width: 100%;
  height: 20%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 1.25rem;
  font-weight: bold;
  text-indent: 2rem;
}
.product_app_item {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.product_app_item > img {
  margin: 0 1.25rem;
}

.research_contents {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.25rem;
  /* margin-bottom: 2.5rem; */
}
.research_contents_child {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.research_contents_child > p {
  width: 100%;
  height: 20%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 1.25rem;
  font-weight: bold;
  text-indent: 2rem;
}
