.mole_app{
    width: 100vw;
    height: 100%;
    background-color: #fff;
}
.mole_title{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mole_title>h1{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
}
.mole_context{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.25rem;
}
.mole_context>p{
    width: 80%;
    /* height: 20%; */
    line-height: 1.875rem;
    text-indent: 3rem;
}
.card_val_app{
    width: 100%;
    /* height: 80vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 1.25rem 0; */
}
.card_val_app_child{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
}
.card_title{
    width: 100%;
    height: 10%;

    display: flex;
    align-items: center;
    text-indent: 3rem;
    font-weight: bold;
}
.card_context{
    width: 100%;
    /* background-color: blue; */
    line-height: 1.875rem;
    text-indent: 4rem;
}
.img_p{
    width: 100%;
    height: 50%;
}
.img_p>img{
width: 50%;
}