.resources_app{
    width: 100vw;
    height: 100%;
    background-color: #fff;
}
.resour_title{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.resour_title>h1{
    width: 80%;height: 100%;
    display: flex;
    align-items: center;
}
.resout_context{
    width: 100%;
    height: 35vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.resout_child{
    width: 80%;
    height: 100%;
    text-indent: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.875rem;
}
.img_p{
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.img_p_child{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.three_p{
    width: 100%;
    height: 10vh;

    display: flex;
    align-items: center;
    justify-content: center;
}
.three_p>p{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-indent: 3rem;
    line-height: 1.875rem;
}

.img_p_two{
    width: 100%;
    height: 45vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.img_p_two_child{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.can_div{
    width: 100%;
    display: flex;
    justify-content: center;
}
.can_div>p{
    width: 80%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.875rem;

}