.pang_app{
    width: 100vw;
    height: 100%;
    background-color: #fff;
}
.pang_title{
    width: 100%;
    height: 10vh;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.pang_title>h1{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
}
.easy_title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.easy_title_child{
    width: 80%;
    height: 100%;
    text-indent: 2.5rem;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.875rem;
}
.easy_title_child>p{
    width: 100%;
    /* height: 20%; */
    /* background-color: pink; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* line-height:1.4375rem; */
}
.easy_title_child>img{
    width: 30%;
    margin: 1.25rem 0;
}
.demo_time{
    width: 100%;
    height: 20%;
    text-indent: 5rem;
}
.demo_parse{
    width: 100%;
    /* height: 165vh; */
    display: flex;
    align-items: center;
    justify-content:center;
    /* margin: 1.25rem 0; */
    padding-bottom: .625rem;
}
.demo_parse_child{
    width: 80%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.demo_parse_title{
    width: 100%;
    height: 7%;
    display: flex;
    align-items: center;
    text-indent: 2.5rem;
    margin: 0.625rem 0;
}
.demo_periodical{
    width: 100%;
    height: 7%;
    text-indent: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.875rem;
}
.demo_periodical>p{
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
}
.demo_periodical>p:nth-child(2)>span{
    padding-right: 2.5rem;
}
.Findings_div{
    width: 100%;
    height: 84%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.find_title{
    width: 100%;
    height: 5%;
    display: flex;
    align-items: center;
    text-indent: 2.5rem;
}
.find_context{
    width: 100%;
    /* height: 25%; */
    /* background-color: blue; */
    line-height: 2.1875rem;
    text-indent: 3.5rem;
}