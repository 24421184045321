.Genomic_app {
    width: 100vw;
    height: 100%;
    background-color: #fff;
}

.Genomic_title {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title_text {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
}

.Product_presentation {
    width: 100%;
    height: 55vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Product_presentation_child {
    width: 80%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.pres_child_top {
    width: 100%;
    height: 30%;
}

.pres_child_top>p:first-child {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    text-indent: 2rem;
    font-weight: bold;
}

.pres_child_top>p:nth-child(2) {
    width: 100%;
    height: 85%;
    display: flex;
    align-items: center;
    text-indent: 3rem;
}

.pres_child_bottom {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: auto;
}

.pres_child_bottom>p {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-indent: 2rem;
}

.pres_child_bottom_item {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    margin-left: 50%;
}

.pres_child_bottom_item>p {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    margin-left: 1.25rem;
}

.Product_App {
    width: 100%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.product_app_child{
    width: 80%;
    height: 100%;
}
.product_app_child>p{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    text-indent: 2rem;
    font-weight: bold;
}
.product_app_img{
    width: 100%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.Technical_process{
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Technical_process_child{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.Technical_process_child>p{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    text-indent: 2rem;
    font-weight: bold;
}
.Technical_process_child>img{
    width: 50%;
    height: 85%;
    margin: auto;
}
.technical_parameter{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.technical_parameter_child{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}
.technical_parameter_child>p{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-indent: 2rem;
    font-weight: bold;
}
.top_param{
    width: 100%;
    height: 40%;

}
.top_param>p{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-indent: 3rem;
}
.top_param>.top_table{
    width: 100%;
    height: 90%;
}
.top_table_title{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.top_table>table{
    width: 80%;
    height: 65%;
    margin: auto;
    text-align: center;
    border-collapse: collapse;
    border: 0.0625rem solid #000;
}
.top_table>table td{
    border: 0.0625rem solid #000;
    
}
.pay_attention_to{
    width: 80%;
    height: 20%;
    /* background-color: black; */
    margin: auto;
}
.bottom_param{
    width: 100%;
    height: 40%;
}

.bottom_param>p{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    text-indent: 3rem;
}

.bottom_param>.bottom_table{
    width: 100%;
    height: 90%;
}
.bottom_table_title{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom_table>table{
    width: 80%;
    height: 65%;
    margin: auto;
    text-align: center;
    border-collapse: collapse;
    border: 0.0625rem solid #000;
}
.bottom_table>table td{
    border: 0.0625rem solid #000;
    
}
.life_cycle{
    width: 100%;
    height: 5%;
    display: flex;
    flex-direction: column;
    text-indent: 3rem;
}
.Case_analysis{
    width: 100%;
    height: 150vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Case_analysis_child{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}
.Case_analysis_child>p{
    width: 100%;
    height: 3%;
    display: flex;
    align-items: center;
    text-indent: 2rem;
    font-weight: bold;
    /* background-color: red; */
}
.demo_journal{
    width: 100%;
    height: 5%;
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-indent: 2rem;
}
.demo_journal>p>span:first-child{
    padding-right: 1.875rem;
}
.research_results{
    width: 100%;
    height: 70%;
}
.research_results>p:first-child{
    width: 100%;
    height: 5%;
    display: flex;
    align-items: center;
    text-indent: 2rem;
}
.context_item{
    width: 100%;
    /* height: 15%; */
    display: flex;
    flex-direction: column;
    line-height: 1.875rem;
}
.context_item>span:first-child{
    text-indent: 2rem;
}
.context_item>span:nth-child(2){
    text-indent: 3.5rem;
}
.references_div{
    width: 100%;
    height: 5%;
    /* background-color: yellow; */
    margin-top: 9.375rem;
}