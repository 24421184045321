.Sequencing_app {
  width: 100vw;
  height: 100%;
  background-color: #fff;
}

.sequecing_title {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sequecing_title > h1 {
  width: 80%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.Technical_introduction {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Technical_introduction_child {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.techn_title {
  width: 100%;
  height: 10%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  text-indent: 2rem;
  margin: 0.4375rem 0;
  font-size: 1.25rem;
  font-weight: bold;
}

.tech_content {
  width: 100%;
  line-height: 1.875rem;
  text-indent: 3rem;
  margin: .625rem 0;
}

.technical_superiority {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.technical_superiority_child {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.technical_superiority_child > ul {
  width: 100%;
  line-height: 1.875rem;
  /* text-indent: 3rem; */
  padding-left: 3rem;
  list-style: disc;
}

.Platform_characteristics {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.25rem 0;
}

.Platform_characteristics_child {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fundamental_principle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fundamental_principle_child {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  margin: 1.25rem 0;
}

.fundamental_principle_child > ol {
    width: 100%;
    line-height: 1.875rem;
    /* text-indent: 3rem; */
    padding-left: 3rem;
  }
  

  .preparation_quality{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .625rem 0;
  }
  .preparation_quality_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
}
.pre_content{
    width: 100%;
    text-indent: 3rem;
    line-height: 1.875rem;
}
.inspection_sequencing{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .625rem 0;
}
.inspection_sequencing_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.ins_content{
    width: 100%;
    text-indent: 3rem;
    line-height: 1.875rem;
}
.document_div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.document_div_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.document_p_one{
    width: 100%;
    text-indent: 2rem;
    line-height: 1.875rem;
    font-size: 1.0625rem;
}
.look_document{
    width: 100%;
    text-indent: 3rem;
    line-height: 1.875rem;
    /* font-size: 1.25rem; */
}
.docu_content{
    width: 100%;
    line-height: 1.875rem;
    text-indent: 3rem;
    margin: .625rem 0;
}

.refer_to{
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 1.875rem;
    margin: 1.25rem 0;
}