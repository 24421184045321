.Functional_app{
    width: 100vw;
    height: 100%;
    background-color: #fff;
}
.func_title{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.func_title>h1{
    width: 80%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.Qualitative_protein{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Qualitative_protein_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.modul_title{
    width: 100%;
    height: 10%;
    text-indent: 2rem;
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0.625rem 0;
}
.On-board{
    width: 100%;
    line-height: 1.5625rem;
    text-indent: 3rem;
}
.On-board>p:first-child{
    color: #0070C0;
}
.Basic_information{
    width: 100%;
    line-height: 1.5625rem;
    padding-left: 3.75rem;
    margin: 0.625rem 0;
}
.Basic_information>p:first-child{
    width: 100%;
    margin-left: -0.8125rem;
    color: #0070C0;
}
.Basic_information>ol{
    width: 100%;
    line-height: 1.5625rem;
}


.Qualitative_protein{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Qualitative_protein_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.Decorate_title{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Decorate_title>h2{
    width: 80%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-indent: 1.5rem;
}


.Decorate_protein{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Decorate_protein_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Phosphorylation_modification{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Phosphorylation_modification_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.demo_jx{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.demo_jx>h2{
    width: 80%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-indent: 2.5rem;
    font-size: 1.25rem;
}

.demo_twoP{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.demo_twoP_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-indent: 2.5rem;
    line-height: 1.875rem;
    margin-bottom: 0.625rem;
}
.result_p{
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-indent: 2.5rem;
}

.result_content{
    width: 94%;
    line-height: 1.875rem;
    text-indent: 2.5rem;
    margin: auto;
}