.chip_app{
    width: 100vw;
    height: 100%;
    background-color: #fff;
}
.chip_title{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chip_title>h1{
    width: 80%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.chip_content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chip_child{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.chip_content_p{
    text-indent: 2rem;
    line-height: 1.875rem;
    margin: .9375rem 0;
}
.content_title{
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-indent: 1.5rem;
    padding: .625rem 0;
}