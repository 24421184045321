.footer_div {
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: black;
    font-size: .75rem;
}
a{
    text-decoration: none;
    color: #000;
}
.footer_top {
    width: 100%;
    display: flex;
    justify-content: center;
}
.footer_top_child{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer_top_left{
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    font-size: 1.25rem;
}

.footer_left_bottom{
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer_top_right{
    width: 75%;
    height: 100%;
    /* background-color: blue; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: .625rem;
}
.right_item{
    width: 19%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    line-height: 1.25rem;
}
.bio_img{
    border-radius: .625rem;
}
.right_item:first-child{
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    line-height: 1.25rem;
}
.title_bro{
    width: 100%;
    font-size: .8125rem;
    margin: .3125rem 0;

}
.title{
    width: 100%;
    padding: 1.25rem 0;
    font-size: .9375rem;
    font-weight: bold;
}
.footer_bottom {
    width: 100%;
    background-color: #bfbcbc;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: .8125rem;
    padding: 1.25rem 0;
}