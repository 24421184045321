.Multi_app {
    width: 100vw;
    height: 100%;
    background-color: #fff;
}

.multi_title {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.multi_title>h1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
}

.easy_context {
    width: 100%;
    /* height: 80vh; */
    line-height: 1.875rem;
    text-indent: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.easy_context>p {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.easy_img{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1.25rem 0;

}
.easy_img>p{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.easy_img_two{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0.625rem;
}
.easy_img_two>p{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.easy_img_two>img{
    width: 60%;
}
.rapeseed_div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rapeseed_child {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
}

.rapeseed_title{
    width: 100%;
    height: 5%;
    /* background-color: blue; */
    font-weight: bold;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-indent: 3rem;
    font-size: 1.25rem;
}
.rapeseed_context{
    width: 100%;
    height: 10%;
    line-height: 2.1875rem;
    text-indent: 3.5rem;
}
.img_one{
    width: 100%;
    height: 40%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.img_one>img{
    width: 70%;
    height: 70%;
}
.img_one>p{
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img_two{
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.img_two>img{
    width: 70%;
    height: 70%;
}
.img_two>p{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_two{
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0.625rem 0;

}
.img_two>img{
    width: 70%;

    height: 70%;




}
.img_two>p{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_three{
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0.625rem 0;
    
}
.img_three>img{
    width: 70%;
    height: 70%;

}
.img_three>p{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_four{
    width: 100%;
    height: 40%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin: 0.625rem 0;
}
.img_four>img{
    width: 70%;
    height: 70%;

    
}
.img_four>p{
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_five{
    width: 100%;
    height: 40%;

    
}

.img_five>img{
    width: 70%;
    height: 70%;

    
}
.img_five>p{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img_six{
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1.25rem 0;
}

.img_six>img{
    width: 70%;
    height: 30%;
    margin-top: 0.625rem;

}
.img_six>p{
    font-size: 0.9375rem;
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_seven{
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.img_seven>p{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.625rem 0;
}

.img_seven>img{
    width: 70%;
    height: 70%;

}

.img_eight{
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0.625rem 0;
    
}
.img_eight>img{
    width: 70%;
    height: 70%;

}
.img_eight>p{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_nine{
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0.625rem 0;
    
}
.img_nine>img{
    width: 70%;
    height: 70%;

}
.img_nine>p{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wheat_div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wheat_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.wheat_title{
    width: 100%;
    height: 10%;
    /* background-color: blue; */
    font-weight: bold;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-indent: 3rem;
    font-size: 1.25rem;
}
.wheat_context{
    width: 100%;
    height: 10%;
    line-height: 2.1875rem;
    text-indent: 3.5rem;
}
.img_ten{
    width: 100%;
    height: 40%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.img_ten>img{
    width: 70%;
    height: 70%;
}
.img_ten>p{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_eleven{
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1.25rem 0;
}
.img_eleven>img{
    width: 50%;
    height: 50%;
}

.img_eleven>p{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.img_twelve{
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0.625rem 0;
    
}
.img_twelve>img{
    width: 70%;
    height: 70%;

}
.img_twelve>p{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.625rem;
}

.bottom_img{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bottom_img_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.bottom_title{
    width: 100%;
    height: 10%;
    text-indent: 3rem;
    line-height: 1.875rem;
}
/* .bottom_img_child>img{
    margin: 1.25rem 0;
} */
.bottom_text{
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
text-indent: 3rem;
margin: 1.25rem 0;
}
img{
    margin: 0.9375rem 0;
}