.intelligent_app {
    width: 100vw;
    height: 100%;
    background-color: #fff;
}

.intell_title {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intell_title>h1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
}

.intell_easy {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.25rem 0;
}

.intell_child {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 1.875rem;
}
.easy_img_one{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1.25rem;
}
.easy_img_one>img{
    width: 50%;
    margin: 0.625rem 0;
}

.breed_title{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.breed_title>h1{
    width: 80%;height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.breed_content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.breed_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.itemP_one{
    width: 100%;
    line-height: 1.875rem;
    text-indent: 2rem;
}

.itemP_two{
    width: 100%;
    line-height: 1.875rem;
    text-indent: 2rem;
}

.img_p{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.875rem;
    margin: 0.625rem 0;
}

.references_p{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.875rem;
    margin: 0.625rem 0;
}