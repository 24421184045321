.Home_app {
    width: 100vw;
    height: 100%;
    margin-top: 5rem;
}

.Home_left {
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home_right {
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.Home_center {
    width: 80%;
    height: 100%;
    font-size: 1.5625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Home_left_button,
.Home_right_button {
    background-color: rgba(0, 0, 0, 0);
}


@media screen and (max-width:768px) {
    .Home_center {
        font-size: 12px;
    }
}



.Hot_products {
    width: 100vw;
    /* height: 75vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9375rem 0;
    margin-top: 3.125rem;
}

.Hot_products_child {
    width: 90%;
    height: 100%;
    /* background-color: red; */
    position: relative;
    box-shadow: 0 0 .3125rem .3125rem #ececec;
}

.hot_title {
    position: absolute;
    top: -7%;
    left: 44.5%;
    /* background-color: #f7f7d9; */
    background: linear-gradient(blue, rgb(192, 232, 255));
    color: white;
    padding: .5rem .9375rem;
    font-weight: bold;
    font-size: 1.5625rem;
    border-radius: .625rem;
    border: 4px solid white;
}

@media screen and (max-width:768px) {

    .hot_title {
        top: -2%;
    }
}

.hot_bottom {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hot_items {
    width: 30%;
    height: 85%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    padding: .625rem .3125rem;
    /* background-color: #F2F2F2; */
    margin-bottom: 1.25rem;
    margin-top: 2.5rem;
    margin-left: .9375rem;
    margin-right: .9375rem;
    border-radius: .625rem;
    border: 1.25rem solid #ececec;
}

.hot_context {
    line-height: 25px;
    font-size: .8125rem;
    padding: 0 25px;
}

.gos {
    font-size: 12px;
    cursor: pointer;
}

.Cooperation_case {
    width: 100vw;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2.5rem 0;
    /* background-color: red; */

}

.Cooperation_case_child {
    width: 90%;
    height: 100%;
    /* background-color: #f00; */
    position: relative;
    /* box-shadow: 0 0 .3125rem .3125rem #ccc; */
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.cooper_title {
    position: absolute;
    top: 0%;
    left: 44.5%;
    /* background-color: #f7f7d9; */
    padding: .5rem .9375rem;
    font-weight: bold;
    font-size: 1.5625rem;
    border-radius: .625rem;
    border: .25rem solid white;
    font-family: "微软雅黑";
    color: white;
    background: linear-gradient(blue, rgb(192, 232, 255));

}

.cooper_bottom {
    width: 100%;
    height: 75%;
    /* background-color: red; */

}
.swier-content {
  height: 120px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  user-select: none;
}
.cooper_item {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: .3125rem 1.0625rem;
    background-color: #fff;
    border-radius: .625rem;
    /* border: .125rem solid blue; */
    padding: 0 .625rem;
    color: #000;
}

.cooper_item_title {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cooper_item_right {
    width: 57%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;

    /* padding-left: .3125rem; */
    padding: 0 .125rem;
}

.cooper_item_right>p:first-child {
    margin: .9375rem 0;
    font-size: 15px;
}

.cooper_left,
.cooper_right {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 90;
    border: none;
}

.cooper_left {
    left: 0;
}

.cooper_right {
    right: 0;
}

.cooper_text {
    width: 100%;
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8; */
}

.about_div {
    width: 100vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.about_div_child {
    width: 90%;
    height: 80%;
    box-shadow: 0 0 .3125rem .3125rem #eff3f7;
    /* padding: 30px 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    position: relative;
}

.about_title {
    width: 13%;
    height: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5rem;
    padding: .625rem 0;
    margin-bottom: 1.875rem;
    background-image: url('../../assets/img/About.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: -7%;
}

.About_Swiper {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_items_title {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5625rem;

}

.center_hr {
    width: .0625rem;
    height: 95%;
    background-color: #ccc;
    display: block;
}

.about_items {
    width: 45%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 1.25rem;
    cursor: pointer;
}


.items_one:hover {
    transform: scale(1.03);
    transition: all 0.6s;
}

.items_two:hover {
    transform: scale(1.03);
    transition: all 0.6s;
}


.newCenter_div {
    width: 100vw;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem 0;
}

.newCenter_div_child {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: .3125rem;
    position: relative;
}

.new_center_title {
    width: 15%;
    /* background-color: red; */
    height: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    background-image: url('../../assets/img/newCenter.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: -7%;
}

.newCenter_List {
    width: 90%;
    height: 85%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* padding: .9375rem 0; */
    border: .1875rem solid #cccccc8a;
/* flex-direction: column; */

}

.newCenter_items {
    width: 30%;
    height: 95%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.newCenter_items>div {
    width: 90%;
    /* height: 35%; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 0.625rem;
}

.newCenter_items>div>p {
    width: 100%;
    /* height: 30%; */
    padding: .125rem 0;
}

.new_Text {
    width: 100%;
    /* height: 35%; */
    font-size: .75rem;
    color: #ccc;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}