.content {
  display: flex;
  flex-direction: row;
}

.items {
  width: 130px;
  color: #fff;
}
.menu {
  /* width: 100%; */
  line-height: 2.8125rem;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: width 0.3s ease, background 0.3s ease, color 0.3s ease; 
}
.menu:hover {
  overflow: visible;
  background: #c7d9ef;
  color: white;
  z-index: 999;
  cursor: pointer;
}
.Drop_div {
  position: absolute;
}
.drop {
  width: 100%;
  padding-right: 30px;
  padding-left: 15px;
  background: white;
  line-height: 2.8125rem;
  overflow: hidden;
  transition: width 0.3s ease, background 0.3s ease, color 0.3s ease; 
}
.drop:hover {
  background: #c7d9ef;
  color: white;
  cursor: pointer;
}
