.detection_app{
    width: 100vw;
    height: 100%;
    background-color: #fff;
}
.detection_title{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.detection_title>h1{
    width: 80%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.Product_introduction{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.625rem 0;
}
.Product_introduction_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 2rem;
    
}
.Product_introduction_child>p{
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 1.875rem;
}

.Kit_introduction{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.625rem 0;
}
.Kit_introduction_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 1.875rem;
    margin-left: 2rem;
}
.Kit_introduction_child>p{
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.Sample_requirements{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Sample_requirements_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 2rem;
line-height: 1.875rem;
}
.Sample_requirements_child>p{
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.test_method{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.test_method_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 2rem;
}
.test_title{
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 0.3125rem 0;
}
.test_method_child>img{
    width: 60%;
}

.operating_procedure{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.operating_procedure_child{
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 2rem;
}

.operating_title    {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 0.3125rem 0;
}

